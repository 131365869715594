/* eslint-disable @next/next/no-img-element */
'use client'
import { type FC, memo } from 'react'
import styled from 'styled-components'
// import { motion } from 'framer-motion'
import { mq, vw, designGrid, font70_70, font45_40, font32_35, font15_18, font13_15, font40_44, font18_18 } from '@/styles'

export const SectionApp:FC = memo(() => {
  return (
    <Section id='app'>
      <hgroup>
        <h2 className='srt-only'>App</h2>
        <p>Controla tu Sky Portal desde tu teléfono. Puedes montarlo incluso sin instalación de interruptor</p>
      </hgroup>
      <ul>
        <li>
          <figure>
            <img src='/images/svg/i--link.svg' alt='Icon link' width={90} height={90} />
          </figure>
          <h3>Conéctalo con tu Smart Home</h3>
          <p>Si tienes Alexa o Google Assistant también puedes controlarlo por voz,  solo di &quot;Alexa, puesta de sol en mi salón!&quot;</p>
        </li>
        <li>
          <figure>
            <img src='/images/svg/i--settings.svg' alt='Icon settings' width={90} height={90} />
          </figure>
          <h3>Tú controlas el sol</h3>
          <p>¿Quieres cenar a la luz del atardecer? Solo tienes que tocar un botón. O déjalo en automático y se comportará exactamente como el sol. También puedes definir tus propios horarios de luz.</p>
        </li>
        <li>
          <figure>
            <img src='/images/svg/i--sunrise.svg' alt='Icon sunrise' width={90} height={90} />
          </figure>
          <h3>Deja que el amanecer te despierte</h3>
          <p>Función despertador: Dile a qué hora quieres despertarte; amanecerá suavemente y te despertarás de manera natural.Sin alarmas, sin estrés. Como en vacaciones.</p>
        </li>
      </ul>
    </Section>
  )
})

const Section = styled.section`
  ${designGrid({})}
  padding: ${vw(60, 'mobile')} 0;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(60, 'tablet')} 0;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(120, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 120px 0;
  }

  hgroup,
  ul {
    grid-column: 1 / span 6;
  }

  > hgroup {
    color: var(--color-dark-blue);
    height: fit-content;
    position: sticky;
    padding-left: ${vw(50, 'mobile')};
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding-left: ${vw(50, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 4;
      padding-left: 0;
      position: sticky;
      top: 137px;
    }

    h2, p {
      ${font32_35(true, 500)}
      letter-spacing: -1.28px;
      margin-bottom: ${vw(40, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(40, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        ${font40_44(false, 500)}
        letter-spacing: -1.6px;
        margin-bottom: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 40px;
      }
    }
  }

  ul {
    ${mq.greaterThan('tablet')} {
      grid-column: 6 / span 7;
    }
  }


  h2,
  h3,
  p {
    text-align: left;
  }

  li {
    background-color: orange;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${vw(50, 'mobile')};
    padding: ${vw(60, 'mobile')} ${vw(50, 'mobile')} ${vw(40, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(50, 'tablet')};
      padding: ${vw(60, 'tablet')} ${vw(50, 'tablet')} ${vw(40, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(50, 'desktop')};
      padding: ${vw(120, 'desktop')} ${vw(90, 'desktop')} ${vw(80, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 50px;
      padding: 120px 90px 80px;
    }

    &:nth-child(1) {
      background-image: url('/images/app-1-alexa.jpg');
    }

    &:nth-child(2) {
      background-image: url('/images/app-2-sol.jpg');
    }

    &:nth-child(3) {
      background-image: url('/images/app-3-amanecer.jpg');
    }
  }

  li,
  h3 {
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 40px;
    }
  }

  h3, p {
    text-shadow: 1px 1px rgba(0, 0, 0, .1);
  }

  h3 {
    ${font45_40(true, 500)}
    text-align: left;
    letter-spacing: -1.8px;

    ${mq.greaterThan('tablet')} {
      ${font70_70(false, 500)}
      letter-spacing: -2.8px;
    }

    + p {
      ${font13_15(true, 400)}
      letter-spacing: -0.52px;

      ${mq.greaterThan('tablet')} {
        ${font18_18(false, 500)}
        letter-spacing: -0.20px;
      }

      ${mq.greaterThan('desktop-md')} {
        ${font15_18(false, 500)}
        letter-spacing: -0.6px;
      }
    }
  }

  figure {
    height: ${vw(45, 'mobile')};
    margin-bottom: ${vw(20, 'mobile')};
    width: ${vw(45, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(45, 'tablet')};
      margin-bottom: ${vw(20, 'tablet')};
      width: ${vw(45, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(90, 'desktop')};
      margin-bottom: ${vw(40, 'desktop')};
      width: ${vw(90, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 90px;
      margin-bottom: 40px;
      width: 90px;
    }

    img {
      filter: invert(1);
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
`
