'use client'
import { type FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font62_55, font32_35, font40_44, font170_153, font13_15, font15_18 } from '@/styles'
import { motion, useTransform, useScroll } from 'framer-motion'

export const SectionVariants:FC = memo(() => {
  const ref = useRef<HTMLElement>(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })
  
  const gradient = useTransform( 
    scrollYProgress, 
    [0, 0.25, 0.5, 0.75, 1],
    [ 
      'linear-gradient(180deg, rgba(213,245,252,1) 0%, rgba(131,175,222,1) 100%)', 
      'linear-gradient(180deg, rgba(213,245,252,1) 0%, rgba(131,175,222,1) 100%)', 
      'linear-gradient(180deg, rgba(133,176,223,1) 0%, rgba(0,43,106,1) 100%)', 
      'linear-gradient(180deg, rgba(133,176,223,1) 0%, rgba(0,43,106,1) 100%)', 
      'linear-gradient(180deg, rgba(133,176,223,1) 0%, rgba(0,43,106,1) 100%)', 
    ] 
  )
  return (
    <Section id='modelos' ref={ref}>
      <div>
        <hgroup>
          <h2 className='srt-only'>Modelos</h2>
          <p>
            Escoge el modelo que se adapte a tu espacio. Si ya tienes un panel de luz, solo tienes que reemplazarlo. En cualquier caso la instalación es facilísima.
          </p>
        </hgroup>
        <ul>
          <li>
            <article>
              <motion.figure style={{ background: gradient }}>
                <img src='/images/variant--600.png' alt='Modelo 600' />
              </motion.figure>
              <a href='https://www.silversanz.com/es/producto/3875' rel='bookmark'>
                <h3>Modelo 600</h3>
                <span>602€ — ¡Lo quiero!</span>
              </a>
            </article>
          </li>
          <li>
            <article>
              <motion.figure style={{ background: gradient }}>
                <img src='/images/variant--300.png' alt='Modelo 300' />
              </motion.figure>
              <a href='https://www.silversanz.com/es/producto/3874' rel='bookmark'>
                <h3>Modelo 300</h3>
                <span>290€ — ¡Lo quiero!</span>
              </a>
            </article>
          </li>
        </ul>
      </div>
    </Section>
  )
})

const Section = styled.section`
  ${designGrid({})}
  padding: ${vw(30, 'mobile')} 0 ${vw(60, 'mobile')};
  margin: 0 auto;
  max-width: 1440px;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(30, 'tablet')} 0 ${vw(60, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(57.5, 'desktop')} 0 ${vw(120, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 57.5px 0 120px;
  }

  > div {
    background-color: white;
    border-radius: ${vw(30, 'mobile')};
    grid-column: 1 / span 6;
    padding: ${vw(40, 'mobile')} 0;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'tablet')};
      padding: ${vw(40, 'tablet')} 0;
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(40, 'desktop')};
      grid-column: 1 / span 12;
      padding: ${vw(80, 'desktop')} 0;
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 40px;
      padding: 80px 0;
    }
  }

  h2 {
    ${font62_55(true, 500)}
    letter-spacing: -2.48px;
    text-align: left;

    ${mq.greaterThan('tablet')} {
      ${font170_153(false, 500)}
    }
  }

  p {
    ${font40_44(true, 500)}
    letter-spacing: -1.6px;

    ${mq.greaterThan('tablet')} {
      ${font40_44(false, 500)}
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      flex-direction: row;
      justify-content: flex-start;
    }

    li {
      &:first-child {
        margin-bottom: ${vw(20, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-bottom: ${vw(20, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          margin-bottom: 0;
          margin-right: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-right: 10px;
        }
      }

      &:last-child {
        ${mq.greaterThan('tablet')} {
          margin-left: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-left: 10px;
        }
      }
    }
  }

  hgroup {
    color: var(--color-dark-blue);
    margin-right: auto;
  }

  hgroup,
  h2 {
    margin-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 40px;
    }
  }

  hgroup,
  ul {
    padding: 0 ${vw(15, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(15, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(110, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 110px;
    }
  }

  article {
    border-radius: ${vw(50, 'mobile')};
    display: flex;
    height: ${vw(438, 'mobile')};
    overflow: hidden;
    position: relative;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(50, 'tablet')};
      height: ${vw(438, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(50, 'desktop')};
      height: ${vw(505, 'desktop')};
      width: ${vw(530, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 50px;
      height: 505px;
      width: 530px;
    }

    &:hover {
      figure {
        transform: scale(1.05);
      }
    }

    figure {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      user-select: none;
      transition: 800ms transform  cubic-bezier(.26,.21,.22,1);;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }

  a {
    align-items: flex-start;
    /* border: 2px solid var(--color-white); */
    border-radius: ${vw(50, 'mobile')};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: ${vw(40, 'mobile')};
    width: 100%;
    z-index: 2;
    transition: 300ms border-color linear;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(50, 'tablet')};
      padding: ${vw(40, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(50, 'desktop')};
      padding: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 50px;
      padding: 40px;
    }

    &:hover {
      /* border: 2px solid var(--color-bright-blue); */
      span {
        background-color: var(--color-bright-blue);
        color: var(--color-white);
      }
    }
  }

  h3 {
    ${font32_35(true, 500)}
    letter-spacing: -1.28px;
    margin-bottom: ${vw(20, 'mobile')};
    text-align: left;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font40_44(false, 500)}
      letter-spacing: -1.6px;
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  span {
    ${font13_15(true, 500)}
    background-color: var(--color-white);
    border-radius: ${vw(30, 'mobile')};
    color: var(--color-dark-blue);
    display: block;
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    transition: 300ms background-color linear, 250ms color linear;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'tablet')};
      padding: ${vw(10, 'tablet')} ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font15_18(false, 500)}
      border-radius: ${vw(30, 'desktop')};
      padding: ${vw(10, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 30px;
      padding: 10px 20px;
    }
  }
`
