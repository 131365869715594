'use client'
import { type FC, memo } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { mq, vw } from '@/styles'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })
const srcVideo = 'https://vimeo.com/927898187'

export const SectionStore:FC = memo(() => {
  return (
    <Section>
      <div className='video-wrapper'>
        <ReactPlayer className='videoplayer' url={srcVideo} width={'100%'} height={'100%'} controls={false} volume={0} muted={true} loop={true} playsinline={true} playing={true} />
      </div>
      <div className='actions'>
        <a href='https://apps.apple.com/es/app/smart-life-smart-living/id1115101477' target='_blank'>
          <img src='/images/badge--app-store.png' alt='Apple store' />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.tuya.smartlife' target='_blank'>
          <img src='/images/badge--google-play.png' alt='Google Play app store' />
        </a>
      </div>
    </Section>
  )
})

const Section = styled.section`
  background-color: black;
  height: ${vw(634, 'mobile')};
  position: relative;
  width: 100vw;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(634, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(548, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 548px;
  }

  .video-wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  iframe {
    aspect-ratio: 16 / 9;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;

    ${mq.greaterThan('desktop')} {
      height: auto !important;
    }
  }

  iframe,
  .videoplayer {
    @media (max-aspect-ratio: 16/9) and (max-width: 768px) {
      height: 100% !important;
      width: auto !important;
    }

    @media (max-aspect-ratio: 16/9) and (min-width: 768px) {
      height: auto !important;
      width: 100% !important;
    }
  }

  .actions {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin: auto auto 0;
    max-width: 1440px;
    padding: ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      align-items: flex-start;
      flex-direction: column;
      padding: ${vw(160, 'desktop')} ${vw(180, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 160px 180px;
    }
  }

  a {
    display: inline-block;
    height: ${vw(50, 'mobile')};
    width: ${vw(150, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(50, 'tablet')};
      width: ${vw(150, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(76, 'desktop')};
      width: ${vw(230, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 76px;
      width: 230px;
    }

    &:first-child {
      margin-right: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-right: ${vw(10, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-right: 0;
        margin-bottom: ${vw(30, 'desktop')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: 30px;
      }
    }
  }
`
