'use client'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font20_22, font45_44, font70_70, font25_27, font32_35, font40_44, font15_18, font18_18 } from '@/styles'
import { SliderFlickity } from './SliderFlickity'

export const SectionRenders:FC = memo(() => {
  const slidesHouse = useMemo(() => (
    [
      <figure key='slide-1'><img src='/images/sliders/casa-1.jpg' alt='Casa slide 1' /></figure>,
      <figure key='slide-2'><img src='/images/sliders/casa-2.jpg' alt='Casa slide 2' /></figure>,
      <figure key='slide-3'><img src='/images/sliders/casa-3.jpg' alt='Casa slide 3' /></figure>,
      <figure key='slide-4'><img src='/images/sliders/casa-4.webp' alt='Casa slide 4' /></figure>
    ]
  ), [])

  const slidesBusiness = useMemo(() => (
    [
      <figure key='slide-business-1'><img src='/images/sliders/negocios-1.jpg' alt='Negocios slide 1' /></figure>,
      <figure key='slide-business-2'><img src='/images/sliders/negocios-2.jpg' alt='Negocios slide 2' /></figure>
    ]
  ), [])

  const slidesOffice = useMemo(() => (
    [
      <figure key='slide-office-1'><img src='/images/sliders/oficinas-1.jpg' alt='oficinas slide 1' /></figure>,
      <figure key='slide-office-3'><img src='/images/sliders/oficinas-3.jpg' alt='oficinas slide 3' /></figure>
    ]
  ), [])

  const slidesWellness = useMemo(() => (
    [
      <figure key='slide-wellness-1'><img src='/images/sliders/wellness-1.jpg' alt='Wellness slide 1' /></figure>,
      <figure key='slide-wellness-2'><img src='/images/sliders/wellness-2.jpg' alt='Wellness slide 2' /></figure>,
      <figure key='slide-wellness-3'><img src='/images/sliders/wellness-3.webp' alt='Wellness slide 3' /></figure>
    ]
  ), [])

  return (
    <Section id='gallery'>
      <hgroup>
        <h2>Convierte cualquier habitación en la mejor habitación</h2>
        <p>Sky Portal es ideal para</p>
      </hgroup>
      <ul>
        <li>
          <SliderFlickity className='fade' elementType='div' options={{ autoPlay: 2000, wrapAround: true, pauseAutoPlayOnHover: false, draggable: false, prevNextButtons: true }}>
            {slidesHouse}
          </SliderFlickity>
          <hgroup>
            <h3>Casa</h3>
            <p>Cocina・ Dormitorio・Salón・Garaje・Despacho</p>
          </hgroup>
        </li>

        <li>
          <SliderFlickity className='fade' elementType='div' options={{ autoPlay: 3000, wrapAround: true, pauseAutoPlayOnHover: false, draggable: false, prevNextButtons: true }}>
            {slidesOffice}
          </SliderFlickity>
          <hgroup>
            <h3>Oficinas</h3>
            <p>Recepción・Salas de reuniones・Despachos・Baños</p>
          </hgroup>
        </li>

        <li>
          <SliderFlickity className='fade' elementType='div' options={{ autoPlay: 3000, wrapAround: true, pauseAutoPlayOnHover: false, draggable: false, prevNextButtons: true }}>
            {slidesBusiness}
          </SliderFlickity>
          <hgroup>
            <h3>Negocios</h3>
            <p>Tiendas・Dentistas・Hoteles・ Consultas médicas</p>
          </hgroup>
        </li>

        <li>
          <SliderFlickity className='fade' elementType='div' options={{ autoPlay: 2000, wrapAround: true, pauseAutoPlayOnHover: false, draggable: false, prevNextButtons: true }}>
            {slidesWellness}
          </SliderFlickity>
          <hgroup>
            <h3>Wellness</h3>
            <p>Gym・Spa・Fisioterapia・Estudios de yoga</p>
          </hgroup>
        </li>
      </ul>
    </Section>
  )
})

const Section = styled.section`
  ${designGrid({})}
  padding: ${vw(60, 'mobile')} 0 ${vw(30, 'mobile')};
  margin: 0 auto;
  max-width: 1440px;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(60, 'tablet')} 0 ${vw(30, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(120, 'desktop')} 0 ${vw(57.5, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 120px 0 57.5px;
  }

  > hgroup {
    grid-column: 1 / span 4;
    margin-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 7;
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }
  }

  ul {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column: 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
    }

    li {
    }
  }

  h2 {
    ${font45_44(true, 500)}
    letter-spacing: -2.7px;
    margin-bottom: ${vw(10, 'mobile')};
    text-align: left;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(10, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font70_70(false, 500)}
      letter-spacing: -2.8px;
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  p {
    ${font20_22(true, 500)}
    letter-spacing: -1.2px;

    ${mq.greaterThan('tablet')} {
      ${font25_27(false, 500)}
      letter-spacing: -1px;
    }
  }

  h2, p {
    color: var(--color-dark-blue);
  }

  li {
    align-items: flex-end;
    border-radius: ${vw(50, 'mobile')};
    display: flex;
    height: ${vw(490, 'mobile')};
    overflow: hidden;
    margin-bottom: ${vw(17, 'mobile')};
    padding: ${vw(40, 'mobile')};
    position: relative;
    /* width: ${vw(384, 'mobile')}; */
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(50, 'tablet')};
      height: ${vw(490, 'tablet')};
      margin-bottom: ${vw(17, 'tablet')};
      padding: ${vw(40, 'tablet')};
      /* width: ${vw(384, 'tablet')}; */
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(50, 'desktop')};
      height: ${vw(772, 'desktop')};
      margin-bottom: ${vw(20, 'desktop')};
      padding: ${vw(40, 'desktop')};
      width: ${vw(530, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 50px;
      height: 772px;
      margin-bottom: 20px;
      padding: 40px;
      width: 530px;
    }

    figure {
      height: 100%;
      position: relative;
      width: 100%!important;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    hgroup {
      z-index: 2;
    }

    h3,
    p {
      color: var(--color-white);
      text-align: left;
    }

    h3 {
      ${font32_35(true, 500)}
      letter-spacing: -1.28px;
      margin-bottom: ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(20, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        ${font40_44(false, 500)}
        letter-spacing: -1.6px;
        margin-bottom: ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 20px;
      }
    }

    p {
      ${font15_18(true, 500)}
      letter-spacing: -.78px;

      ${mq.greaterThan('tablet')} {
        ${font18_18(false, 500)}
        letter-spacing: -.25px;
      }

      ${mq.greaterThan('desktop-md')} {
        ${font15_18(false, 500)}
        letter-spacing: -.6px;
      }
    }

    > div {
      bottom: 0;
      position: absolute;
      right: 0;
      height: 100%;
      width: 100%;
    }

    .flickity-slider {
      height: ${vw(490, 'mobile')};
      width: 100%;

      ${mq.greaterThan('nexus7')} {
        height: ${vw(490, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(772, 'desktop')};
        width: ${vw(530, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 772px;
        width: 530px;
      }
    }
  }

  .flickity-button.previous {
    display: none;
  }

  .flickity-button.next {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
  }
`
