'use client'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font45_44, font70_70, font20_22, font25_27 } from '@/styles'
import { shuffleArray } from '@/utils'
import { SliderFlickity } from './SliderFlickity'

const getSlides = () => shuffleArray([
  <figure key='slide-1'><img src='/images/sliders/casa-1.jpg' alt='Casa slide 1' /></figure>,
  <figure key='slide-2'><img src='/images/sliders/casa-2.jpg' alt='Casa slide 2' /></figure>,
  <figure key='slide-3'><img src='/images/sliders/casa-3.jpg' alt='Casa slide 3' /></figure>,
  // <figure key='slide-4'><img src='/images/sliders/casa-4.webp' alt='Casa slide 4' /></figure>,
  <figure key='slide-business-1'><img src='/images/sliders/negocios-1.jpg' alt='Negocios slide 1' /></figure>,
  <figure key='slide-business-2'><img src='/images/sliders/negocios-2.jpg' alt='Negocios slide 2' /></figure>,
  <figure key='slide-office-1'><img src='/images/sliders/oficinas-1.jpg' alt='oficinas slide 1' /></figure>,
  <figure key='slide-office-3'><img src='/images/sliders/oficinas-3.jpg' alt='oficinas slide 3' /></figure>,
  <figure key='slide-wellness-1'><img src='/images/sliders/wellness-1.jpg' alt='Wellness slide 1' /></figure>,
  <figure key='slide-wellness-2'><img src='/images/sliders/wellness-2.jpg' alt='Wellness slide 2' /></figure>,
  <figure key='slide-wellness-3'><img src='/images/sliders/wellness-3.webp' alt='Wellness slide 3' /></figure>
])

export const HeaderHome: FC = memo(() => {
  const SlidesHeader = useMemo(() => getSlides(), [])

  return (
    <Header>
      <hgroup>
        <img src='/images/svg/imagotype-skyportal.svg' alt='SkyPortal sun imagotype' />
        <h1>Parece una claraboya</h1>
        <p>Pero es un panel de luz <span>smart</span> que puedes instalar en cualquier habitación interior</p>
      </hgroup>
      <SliderFlickity className='fade' elementType='div' options={{ autoPlay: 5000, wrapAround: true, pauseAutoPlayOnHover: false, draggable: false, prevNextButtons: true }}>
        {SlidesHeader}
      </SliderFlickity>
    </Header>
  )
})

const Header = styled.header`
  ${designGrid({})}
  padding: 80px 0 ${vw(60, 'mobile')};
  margin: 0 auto;
  max-width: 1440px;

  ${mq.greaterThan('nexus7')} {
    padding: 80px 0 ${vw(60, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    grid-row: 1 / span 1;
    padding: 80px 0 ${vw(60, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 80px 0 60px;
  }

  hgroup {
    height: ${vw(350, 'mobile')};
    margin-bottom: ${vw(12, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(350, 'tablet')};
      margin-bottom: ${vw(12, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: 0;
    }
  }

  > figure,
  .slider {
    height: ${vw(450, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(450, 'tablet')};
    }
  }

  hgroup,
  > figure,
  .slider {
    background-color: var(--color-white);
    border-radius: ${vw(30, 'mobile')};
    grid-column: 1 / span 6;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(30, 'desktop')};
      grid-row: 1 / span 1;
      height: ${vw(766, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 30px;
      height: 766px;
    }
  }

  hgroup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${vw(40, 'mobile')} ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(40, 'tablet')} ${vw(25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 7 / span 6;
      padding: 0 ${vw(55, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 55px;
    }

    img, h1 {
      margin-bottom: ${vw(15, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(15, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(22, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 22px;
      }
    }


    img {
      height: ${vw(47, 'mobile')};
      object-fit: contain;
      margin-left: 0;
      margin-right: auto;
      width: ${vw(47, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(47, 'tablet')};
        width: ${vw(47, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(93, 'desktop')};
        width: ${vw(93, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 93px;
        width: 93px;
      }
    }
  }

  > figure,
  .slider {
    overflow: hidden;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 6;
    }

    img {
      object-fit: cover;
    }
  }

  h1 {
    ${font45_44(true, 500)}
    color: var(--color-bright-blue);
    text-align: left;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      ${font70_70(false, 500)}
      grid-column: auto / span 6;
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 15px;
    }
  }

  p {
    ${font20_22(true, 500)}
    color: var(--color-dark-blue);
    letter-spacing: -.8px;

    ${mq.greaterThan('tablet')} {
      ${font25_27(false, 500)}
      letter-spacing: -1px;
    }

    span {
      font-style: italic;
    }
  }

  .flickity-button.previous {
    display: none;
  }

  .flickity-button.next {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
  }
`
