'use client'
import { type FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font45_44, font70_70, font13_15, font15_18, font18_18 } from '@/styles'
import { motion, useTransform, useScroll } from 'framer-motion'

export const SectionMotto:FC = memo(() => {
  const ref = useRef<HTMLElement>(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  const gradient = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [
      'linear-gradient(130deg, rgba(101,100,137,1) 0%, rgba(199,134,102,1) 35%, #f5c49fe2 50%,  rgba(199,134,102,1) 65%, rgba(101,100,137,1) 100%)',
      'linear-gradient(130deg, rgba(101,100,137,1) 0%, rgba(199,134,102,1) 35%,  #f5c49fe2 50%,  rgba(199,134,102,1) 65%, rgba(101,100,137,1) 100%)',
      'linear-gradient(130deg, rgba(101,100,137,1) 0%, rgba(199,134,102,1) 35%,  #f5c49fe2 50%,  rgba(199,134,102,1) 65%, rgba(101,100,137,1) 100%)',
      'linear-gradient(160deg, rgba(35,33,77,1) 0%, rgba(73,78,157,1) 25%, #8a76ce 50%, rgba(73,78,157,1) 75%, rgba(35,33,77,1) 100%)',
      'linear-gradient(160deg, rgba(35,33,77,1) 0%, rgba(73,78,157,1) 25%,#8a76ce 50%, rgba(73,78,157,1) 75%, rgba(35,33,77,1) 100%)',
    ]
  )
  return (
    <Section ref={ref} style={{ background: gradient }}>
      {/* <Lottie name='sundown-lottie' speed={1} isActive={inView} /> */}
      <hgroup>
        <h2>Disfruta la belleza de la luz natural</h2>
        <p>La luz del Sky Portal cambia de dirección y color durante el día, exactamente igual que el sol.<br /> Los amaneceres y crepúsculos son especialmente bonitos. </p>
        <p>Convierte cualquier habitación en exterior como por arte de magia.</p>
      </hgroup>
    </Section>
  )
})

const Section = styled(motion.section)`
  ${designGrid({})}
  overflow: hidden;
  padding: ${vw(160, 'mobile')} ${vw(25, 'mobile')};
  position: relative;
  width: 100vw;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(160, 'tablet')} ${vw(25, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(160, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 160px 0;
  }

  hgroup {
    grid-column: 1 / span 6;
    margin: 0 auto;
    max-width: 1440px;
    z-index: 2;

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
    }
  }

  h2 {
    ${font45_44(true, 500)}
    letter-spacing: -1.8px;
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font70_70(false, 500)}
      letter-spacing: -2.8px;
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  p {
    ${font13_15(true, 500)}
    letter-spacing: -.52px;

    ${mq.greaterThan('tablet')} {
      ${font18_18(false, 500)}
      letter-spacing: -.25px;
    }

    ${mq.greaterThan('desktop-md')} {
      ${font15_18(false, 500)}
      letter-spacing: -.6px;
    }
  }

  h2,
  p {
    text-align: center;
  }

  > .lottie-container {
    aspect-ratio: 16 / 9;
    left: 50%;
    min-width: 100vw;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    svg {
      width: auto !important;
    }
  }
`
