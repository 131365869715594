'use client'
import { type FC, memo } from 'react'
import styled from 'styled-components'
import { SectionText } from '@/components/SectionText'
import { SectionRenders } from '@/components/SectionRenders'
import { SectionVariants } from '@/components/SectionVariants'
import { SectionMotto } from '@/components/SectionMotto'
import { SectionStore } from '@/components/SectionStore'
import { SectionApp } from '@/components/SectionApp'

export const MainHome:FC = memo(() => {
  return (
    <Main>
      <SectionText />
      <SectionRenders />
      <SectionVariants />
      <SectionMotto />
      <SectionStore />
      <SectionApp />
    </Main>
  )
})

const Main = styled.main`

`
