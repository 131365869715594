'use client'
import { type FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font20_22, font40_44, font18_18 } from '@/styles'
import { motion, useScroll, useTransform } from 'framer-motion'

export const SectionText:FC = memo(() => {
  const ref = useRef<HTMLElement>(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  const gradientValue = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    [
      'linear-gradient(45deg, #6893cb 0%, #b5d4ef 20%,  #f3efc0 45%, #b5d4ef 80%, #6893cb 100%)',
      'linear-gradient(45deg, #6893cb 0%, #b5d4ef 20%,  #f3efc0 45%, #b5d4ef 80%, #6893cb 100%)',
      'linear-gradient(60deg, #6ca7f4 0%, #c6dcf8 20%, #f9f8c0 45%, #6ca7f4 80%, #6ca7f4 100%)',
      'linear-gradient(90deg, #60a1f7 0%, #a1c5f4 20%,  #f9f8c0 45%,#b6d0f3 80%, #4083da',
      'linear-gradient(90deg, #60a1f7 0%,  #a1c3f1 20%, #f9f8c0 45%, #9dc0ee 80%, #3b86e8 100%)',
    ]
  )

  return (
    <Section ref={ref} style={{ background: gradientValue }}>
      <p>Nuestra biología está optimizada para la luz solar.</p>
      <p>Al pasar tanto tiempo en interiores, nuestros ritmos circadianos(el reloj interior) se descompensan. Dormimos peor, tenemos poca energía y nos sentimos desanimados.</p>
      <p>El Sky Portal no solo es una luz que hace bonitos los espacios, es también un dispositivo terapéutico que mejora tu salud.</p>
      <a href='https://www.silversanz.com/es/categoria/3/subcategoria/132/productos'>Pruébalo, se nota</a>
    </Section>
  )
})

const Section = styled(motion.section)`
  ${designGrid({})}
  overflow: hidden;
  padding: ${vw(80, 'mobile')} 0;
  position: relative;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(80, 'tablet')} 0;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(160, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 160px 0;
  }

  p,
  a {
    color: var(--color-dark-blue);
    z-index: 2;
  }

  p {
    grid-column: 1 / span 6;
    ${font20_22(true, 500)}
    letter-spacing: -.8px;
    margin-bottom: ${vw(20, 'mobile')};
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    text-align: center;

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
      ${font40_44(false, 500)}
      letter-spacing: -1.6px;
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-bottom: ${vw(40, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(40, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 40px;
      }
    }
  }

  a {
    ${font18_18(true, 500)}
    align-items: center;
    background-color: var(--color-white);
    border-radius: ${vw(30, 'mobile')};
    display: flex;
    grid-column: 2 / span 4;
    justify-content: center;
    letter-spacing: -0.4px;
    margin: 0 auto;
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    transition: 250ms background-color linear, 300ms color linear;
    white-space: nowrap;
    width: fit-content;

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'tablet')};
      padding: ${vw(10, 'tablet')} ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font20_22(false, 500)}
      border-radius: ${vw(30, 'desktop')};
      grid-column: 6 / span 2;
      letter-spacing: -0.8px;
      padding: ${vw(10, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 30px;
      padding: 10px 20px;
    }

    &:hover {
      background-color: var(--color-dark-blue);
      color: var(--color-white);
    }
  }

  > .lottie-container {
    aspect-ratio: 16 / 9;
    left: 50%;
    min-width: 100vw;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;

    svg {
      width: auto !important;
    }
  }
`
